import { _ } from "core-js"

class FormHandling {
    constructor (form, fields) {
        this.form = form
        this.fields = fields
    }

    validateFields (field) {
        const mailFormatRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        const htmlTagRegEx = /<(\w+)((?:\s+\w+(?:\s*=\s*(?:(?:"[^"]*")|(?:'[^']*')|[^>\s]+))?)*)\s*(\/?)>/

        if (field.required && field.value.trim() === '') {
            this.setStatus(field, 'error', 'Feld muss gefüllt sein')
        } else {
            this.setStatus(field, 'success')

            switch (field.type) {
                case 'email':
                    // check if value is a valid email-format    
                    if (field.value.match(mailFormatRegEx)) {
                        this.setStatus(field, 'success')
                    } else {
                        this.setStatus(field, 'error', 'Eingabe muss eine E-Mailadresse sein')
                    }
                    break
                case 'text':
                    // check if textfield is filled when required
                    if (field.required === 1 && field.value.length < 2) {
                        this.setStatus(field, 'error', 'Es müssen mindestens 2 Zeichen eingegeben werden')
                    }
                    // check if value contains htmltags 
                    else if (field.value.match(htmlTagRegEx)) {
                        this.setStatus(field, 'error', 'Ungültige Zeichenfolgen')
                    }
                    // check if value is longer than 50 signs 
                    else if (field.value.length > 50) {
                        this.setStatus(field, 'error', 'Eingabe ist zu lang: max. 50 Zeichen')
                    } else {
                        this.setStatus(field, 'success')
                    }
                    break
                case 'checkbox': 
                    // check if required checkbox is not checked
                    if (field.required && !field.checked) {
                        this.setStatus(field, 'error', 'Muss gecheckt sein')
                    } else {
                        this.setStatus(field, 'success')
                    }
            }
        }
    }

    setStatus (field, status, message) {
        const errorMessage = field.parentElement.querySelector('.error-message')

        if (status === 'success') {
            errorMessage.innerText = ''
            field.classList.remove('input-error')
        }

        if (status === 'error') {
            errorMessage.innerText = message
            field.classList.add('input-error')
            field.focus()
        }
    }

    checkHoneypot (field) {
        switch (field.id) {
            case 'input-chocolate-chips':
                if (field.value != '') {
                    field.classList.add('input-error')
                }
                break
            case 'input-gummy-bears':
                if (field.value != 'this-is-fine') {
                    field.classList.add('input-error')
                }
        }
    }

    validateOnSubmit () {
        this.fields.pop()
        
        this.fields.forEach(field => {
            if (field.id != 'input-chocolate-chips' && field.id != 'input-gummy-bears') {
                this.validateFields(field)
            } else {
                this.checkHoneypot(field)
            }
        });
    }
}

export default FormHandling