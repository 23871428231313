import { defineStore } from 'pinia'

export const useFormStore = defineStore('formStore', {
  state: () => ({
    forms: [],
    formLoaded: false
  }),
  actions: {
    /**
     *
     * @param {*} payload
     */
    pushForm (payload) {
      this.forms.push(payload)
    },

    setLoaded () {
      this.formLoaded = true
    }
  }
})
